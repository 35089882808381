<template>
  <div class="_content">
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <a-collapse v-model="activeKey">
          <a-collapse-panel
            v-for="item in data"
            :key="item.id"
            :header="item.ask"
          >
            <p>
              {{ item.answer }}
            </p>
          </a-collapse-panel>
        </a-collapse></a-col
      >
    </a-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeKey: [],
      data: [],
    };
  },

  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$store.dispatch("getPageFaq").then((response) => {
        this.data = response.data.data;
      });
    },
  },
};
</script>
